<template>
  <div class="pl-16 mx-auto mt-8">
    <div class="">
      <button @click="backbutton" class="px-4 bg-slate-200 mr-5 py-2">
        Back
      </button>
      Insert Items Batch
    </div>
    <div class="p-4">Group : {{ productsStore.groupName }}</div>
    <div id="example"></div>
    <div class="text-green-700">{{ note }}</div>
    <div class="my-4">
      <button
        @click="Add"
        type="button"
        class="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Add Items
      </button>

      <button
        @click="clear"
        type="button"
        class="rounded-md bg-indigo-600 py-2 mx-3 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Clear
      </button>
    </div>
  </div>
</template>

<script setup>
import _ from "lodash";
import { nanoid } from "nanoid";
const hotRef = ref(null);
const productsStore = useProductsStore();

//get query params
const { query } = useRoute();
const { productId } = query;
const note = ref("");
console.log(productId);

onMounted(() => {
  setTimeout(() => {
    gen();
  }, 50);
});

const Add = async () => {
  note.value = "Adding items...";

  const hot = hotRef.value;
  const data = hot.getData();

  let list = [];
  let val = new Date().valueOf();
  // loop through the data and remove the empty rows
  for (let i = 0; i < 24; i++) {
    //if price is NaN, make 0
    let price = 0;
    if (isNaN(parseFloat(data[i][1]))) {
      data[i][1] = 0;
    } else {
      price = parseFloat(data[i][1]);
    }

    let d = {
      name: data[i][0],
      price: price,
      printer: buildPrinter(data[i][2]),
      productId: nanoid(8),
      level: 2,
      parentId: productsStore.groupId,
      _printers: data[i][2],
      row: val + i,
    };
    list.push(d);
  }

  list = _.filter(list, (item) => item.name && item.name !== "");

  // loop through the data and remove the sample rows

  await productsStore.insertBatch(list);
  note.value = "Items added successfully";
  clear();
};

const clear = () => {
  const hot = hotRef.value;
  hot.clear();
};

const backbutton = () => {
  useRouter().push("/menu/items");
};

function buildPrinter(string) {
  let list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let res = {};

  list.forEach((element) => {
    let isIN = _.includes(string, element);
    if (isIN) {
      res[`p${element}`] = true;
    }
  });

  return res;
}

function gen() {
  const container = document.querySelector("#example");

  const hot = new Handsontable(container, {
    data: [
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],

      ["Beer SINGHA (sample)", "499", "1,2"],
      ["COKE (sample)", "499", "2"],
    ],
    colHeaders: ["item name", "Price", "Printers"],
    rowHeaders: true,
    colWidths: [300, 100, 100],
    height: "auto",
    licenseKey: "non-commercial-and-evaluation", // for non-commercial use only
    maxRows: 27,
  });
  hotRef.value = hot;
  hot.updateSettings({
    cells(row, col) {
      const cellProperties = {};

      if (row == 25 || row == 26) {
        cellProperties.readOnly = true;
      }

      return cellProperties;
    },
  });
}
</script>
